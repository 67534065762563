import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ErrorMessage from "../error-msg";

const CustomPhoneInput = ({ error, ...props }) => {
  return (
    <div className="flex flex-col">
      <PhoneInput
        country={"et"}
        onlyCountries={["et"]}
        countryCodeEditable={false}
        {...props}
        placeholder="phone number"
        containerClass="!h-12"
        inputClass={`!w-full !h-full !border-neutral-400 !bg-slate-50 !outline-none  ${
          error ? "!border-red-300" : "!border-none"
        }`}
        buttonClass={`!bg-slate-50 !border-r-0 ${
          error ? "!border-red-300" : "!border-none"
        }`}
      />
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default CustomPhoneInput;
