import { FC } from "react";

const ErrorMessage: FC<{ error: string }> = ({ error }) => {
  return (
    <div className="p-2">
      <span className="text-red-500 text-xs">{error}</span>
    </div>
  );
};

export default ErrorMessage;
