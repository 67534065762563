import { DoubleBackIcon, DoubleNextIcon } from "../../utils/icons";
import React, { useState, useEffect, ChangeEvent } from "react";
import ReactPaginate from "react-paginate";
import "./PaymentPaginations.css";

const PaymentPaginations = ({
  data,
  loading,
  fetchData,
  setPageSize,
  setCurrentPage,
  currentPage,
  filtering,
  amount,
}) => {
  // const [pageCount, setPageCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    // console.log("dataprops", data);
    const totalEntries = data?.meta_data?.total;
    const entriesPerPage = data?.meta_data?.per_page;
    setPageCount(Math.ceil(totalEntries / entriesPerPage));

    // Get the number of entries in the data array
    const numberOfEntries = data?.meta_data?.total || 0;

    // Create an array of option values based on the number of entries
    const optionValues = Array.from(
      // { length: numberOfEntries },
      { length: 50 },
      (_, index) => index + 1
    );

    // Create an array of <option> elements
    const optionElements = optionValues.map((value) => (
      <option key={value} value={value}>
        {`${value} rows`}
      </option>
    ));

    setOptions(optionElements);
  }, [data]);

  // useEffect(() => {
  //   console.log("asyncAction", asyncAction);
  // }, [asyncAction]);

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
    // setPaginate(true);
    // console.log("paginate", paginate);
    // Fetch data for the selected page from your API
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0); // Go to the first page

    fetchData(1, 20, filtering, amount);
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1); // Go to the last page
    fetchData(pageCount, 20, filtering, amount);
  };

  const handlePageClick = (data) => {
    const selected = data.selected;
    setSelectedPage(selected);
    onPageChange({ selected });

    // setFiltering && setFiltering(true);
    fetchData(selected + 1, 20, filtering, amount);
  };

  const handleOptionChoose = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setPageSize(selectedValue);
    // setFiltering && setFiltering(true);
    // console.log("selectedValue", selectedValue);
    fetchData(selectedPage + 1, selectedValue, filtering, amount);
  };

  useEffect(() => {
    // return () => {
    setCurrentPage(0);
    // };
  }, []);

  return (
    <div className="flex items-center justify-between w-full h-full">
      <div className="flex items-center justify-around text-[.9rem] gap-1">
        <span>Showing</span>
        <div className="flex items-center gap-1">
          <div className="border border-gray-500 rounded ">
            <select
              name="rows_number"
              id="rows_number"
              className="!outline-none !border-none bg-none bg-transparent"
              onChange={handleOptionChoose}
            >
              <option value="">{data?.data?.length} rows</option>
              {!loading && options}
            </select>
          </div>
          <span>
            of{" "}
            {data?.meta_data?.total
              ? data?.meta_data?.total
              : data?.data?.length
              ? data?.data?.length
              : 0}
          </span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div
          className="flex items-center justify-center border border-[#ccc] h-[25px] w-[25px] rounded-[5px] cursor-pointer"
          onClick={handleFirstPageClick}
        >
          <DoubleBackIcon />
        </div>
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel="..."
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination-container"
          pageClassName="pagination-page"
          activeClassName="pagination-active"
          pageRangeDisplayed={3} // Set the number of pages to display in the middle
          marginPagesDisplayed={1}
          forcePage={currentPage}
        />
        <div
          className="flex items-center justify-center border border-[#ccc] h-[25px] w-[25px] rounded-[5px] cursor-pointer"
          onClick={handleLastPageClick}
        >
          <DoubleNextIcon />
        </div>
      </div>
    </div>
  );
};

export default PaymentPaginations;
