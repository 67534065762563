import React from "react";

const SkeletonLoading = () => {
  // Create an array to represent the number of rows you want to display
  const rowCount = 20;

  // Create an array with the length of rowCount to map through and render skeleton rows
  const skeletonRows = Array.from({ length: rowCount }, (_, index) => (
    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
      <td className="border p-1.5 animate-pulse">Loading...</td>
    </tr>
  ));
  return <tbody>{skeletonRows}</tbody>;
};

export default SkeletonLoading;
