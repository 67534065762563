import VissibilityOnIcon from "../../../assets/icons/vissibility-on.svg";
import VissibilityOffIcon from "../../../assets/icons/vissibility-off.svg";

import { useState } from "react";
import ErrorMessage from "../error-msg";

export const TextInput = ({ type, error, prefixIcon, ...props }) => {
  const [inputType, setinputType] = useState(type);

  const [showPassword, setShowPassword] = useState(false);

  const onShowPasswordChange = () => {
    inputType === "password" ? setinputType("text") : setinputType("password");
    setShowPassword((sh) => !sh);
  };

  return (
    <div className="flex">
      <div className="relative w-full bg-slate-50 rounded-xl border justify-start items-center gap-1 inline-flex">
        {prefixIcon && (
          <div className="relative w-6 bg-lime-400">
            <div className="left-1 top-1/2 -translate-y-1/2 absolute ">
              {prefixIcon}
            </div>
          </div>
        )}

        <input
          {...props}
          type={inputType}
          className=" w-full p-4 !outline-none border-none bg-slate-50  text-neutral-400 text-[16px] font-normal leading-none"
        />
        <div className="relative">
          {type === "password" && (
            <div
              onClick={onShowPasswordChange}
              className="right-[5px] top-1/2 -translate-y-1/2 absolute cursor-pointer"
            >
              {!showPassword ? (
                <VissibilityOnIcon className="w-8 h-8" />
              ) : (
                <VissibilityOffIcon className="w-8 h-8" />
              )}
            </div>
          )}
        </div>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};
