import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Component/pages/Auth/Login";
import Table from "./Component/pages/Payments/Table";
import PrivateRoute from "./Component/PrivateRoute/PrivateRoute";
// Import your PrivateRoute component

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect from root to /payments */}
        <Route path="/" element={<Navigate to="/payments" replace />} />

        <Route path="/login" element={<Login />} />

        {/* Private route for /table */}
        <Route element={<PrivateRoute />}>
          <Route path="/payments" element={<Table />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
