import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import myImage from "../../../assets/icons/ChipChip.png";
import CustomPhoneInput from "../../CustomInputs/input/phone-input";
import { TextInput } from "../../CustomInputs/input/text-input";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

function Login() {
  const [password, setPassword] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onShowPasswordChange = () => {
    inputType === "password" ? setinputType("text") : setinputType("password");
    setShowPassword((sh) => !sh);
  };

  async function loginUser(cred) {
    try {
      const response = await fetch(
        "https://stagingapi.chipchip.social/v1/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country: "ETH",
            phone: cred.phone,
            password: cred.password,
          }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        setError(error.error.message);
        // throw new Error(error.message || "Login failed");
      }

      const data = await response.json();
      // console.log("data", data);
      localStorage.setItem("insa-user", JSON.stringify(data));
      // return redirect("/table")
      // return data.user;
      // window.location.href = "/";
      navigate("/payments");
    } catch (error) {
      console.error("Error logging in:", error);

      // throw error;
    }
  }

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      // console.log("Phone-Pass", { password, phone });
      const response = await loginUser({ password, phone });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Login failed:", error);
    }
  };

  // useEffect(() => {
  //   console.log("password", password);
  // }, [password]);
  // useEffect(() => {
  //   console.log("phone", phone);
  // }, [phone]);

  return (
    <div className="w-screen h-screen bg-gray-100 flex items-center justify-center">
      {/* form container */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-16 bg-white rounded-xl flex-col justify-center items-center gap-8 inline-flex"
      >
        <div className="h-40 w-40 p-1 overflow-hidden">
          <img src={myImage} alt="ChipChip.png" />
        </div>

        {/* phone number input */}
        <div className="w-[428px]">
          <Controller
            control={control}
            name="phone"
            rules={{ required: "required" }}
            render={({ field: { ref, ...field } }) => (
              <CustomPhoneInput
                {...field}
                error={errors.phone ? errors.phone?.message : undefined}
                onChange={(phone) => {
                  field.onChange(phone);
                  setPhoneNumber(phone);
                }}
                inputProps={{
                  ref,
                  required: true,
                  autoFocus: true,
                }}
              />
            )}
          />
        </div>

        {/* password */}
        <div className="w-[428px]">
          <TextInput
            {...(register("password"), { required: true })}
            error={errors.password ? errors.password?.message : undefined}
            placeholder="password"
            type="password"
            onChange={(el) => setPassword(el.target.value)}
          />
        </div>
        <div className="flex flex-col w-full items-center">
          {error && (
            <div className="p-2">
              <span className="text-red-500">{error}</span>
            </div>
          )}
          {/* sign in btn */}
          <div className="w-full">
            <button
              disabled={loading}
              className={` bg-primary p-3 disabled:bg-opacity-60 hover:bg-opacity-60 rounded-lg w-full text-white `}
            >
              {!loading ? (
                <span className="">Sign In</span>
              ) : (
                <>
                  <span className="loading loading-spinner"></span>
                  loading
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
